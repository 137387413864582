// eslint-disable-next-line no-redeclare,no-unused-vars
function init() {
  // Currently static, in the future could be set as an environment variable
  window.backendHost = 'kuechendesk';
  window.endpoint = getEndpoint();
  window.mobile = getMobile();

  // start and decide next js
  var pm = {func: 'start'};
  if (document.cookie.search('login=') !== -1) {
    var login = decodeURI(
      document.cookie.substring(document.cookie.search('login') + 6)
    );
    var nextCookie = login.search(';');
    if (nextCookie > 0) login = login.substring(0, nextCookie);
    [pm.user, pm.password] = login.split('%2C');
  }
  if (localStorage.getItem('debug')) pm.debug = localStorage.getItem('debug');
  $.ajax({
    url: endpoint,
    headers: {},
    xhrFields: {withCredentials: true},
    method: 'POST',
    data: pm,
    success: function (xhr) {
      if (xhr.redirect) document.location = xhr.redirect;
      else if (xhr.next) load(xhr.next, xhr);
    },
  });
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function load(obj, xhr) {
  window[obj](xhr);
}

function getMobile() {
  const windowWidth = window.innerWidth;

  // Screen breakpoints taken from _variables.scss
  if (windowWidth < 768) {
    return 'smartphone';
  }
  if (windowWidth <= 1200) {
    return 'tablet';
  }

  // For backwards compatability - the code treats undefined as desktop
  return undefined;
}

function getEndpoint() {
  const isLocal =
    location.hostname.includes('localhost') ||
    location.hostname === '127.0.0.1';
  return isLocal ? getLocalEndpoint() : getCloudEndpoint();
}

// Example return: http://kuechendesk.localhost/foobar/
function getLocalEndpoint() {
  const urlSearchParams = new URLSearchParams(location.search);
  const client = urlSearchParams.get('client');

  if (!client) {
    throw Error('URL query string is is missing client param');
  }

  return `${location.protocol}//${window.backendHost}.localhost/${client}/`;
}
function getCloudEndpoint() {
  const [client, subdomain] = location.hostname.split('.');
  const testEnvironmentSubdomains = new Set([
    'sandbox',
    'staging1',
    'staging2',
    'staging3',
    'staging4',
    'staging5',
  ]);
  const isTestEnvironment = testEnvironmentSubdomains.has(subdomain);

  return isTestEnvironment
    ? `https://${client}.${subdomain}.api.${window.backendHost}.cloud/`
    : `https://${client}.api.${window.backendHost}.cloud/`;
}
